import React from 'react';
import { Box, Typography, Grid, Card, CardHeader, CardMedia, Link } from '@mui/material';

import { blueGrey } from '@mui/material/colors';

import NewsArticle from '../../../component/news-article';

import Imgscopes from '../../../../img/media/newsroom/_2023/scopes.jpeg';
import Imgnjacts from '../../../../img/media/newsroom/_2023/njacts.jpg';
import Imgdia from '../../../../img/media/newsroom/_2023/DIA-Logo.png';
import Imgacrp from '../../../../img/media/newsroom/_2023/ACRP.jpeg';
import Imgfob from '../../../../img/media/newsroom/_2023/image-logos.png';
import Imgiscr from '../../../../img/media/newsroom/_2023/ISCR Logo.png';
import Imguspto from '../../../../img/media/newsroom/_2023/USPTO-Logo.png';
import Imgbmc from '../../../../img/media/newsroom/_2023/BMC1.jpeg';
import ImgYahoo from '../../../../img/media/newsroom/_2023/ImgYahoo.png';
import ImgPlosNTD from '../../../../img/media/newsroom/_2023/PLOS-NTD_Logo.png';
import ImgFeatCust from '../../../../img/media/newsroom/_2023/FeaturedCustomers.png';
import ImgFastComp from '../../../../img/media/newsroom/_2023/FastCompanyExecutiveBoard_Logo.svg';
import ImgMDGH from '../../../../img/media/newsroom/_2023/MDGHLogo.jpeg';
import ImgWVC from '../../../../img/media/newsroom/_2023/world-vaccine-congress-west-coast-logo-wvcwc-white-background.webp';
import ImgForbes from '../../../../img/media/newsroom/_2023/Forbes.png';
import ImgGartner from '../../../../img/media/newsroom/_2023/Gartner_logo.svg.png';
import ImgAxtria from '../../../../img/media/newsroom/_2023/Axtria_Logo.png';
import ImgAMA from '../../../../img/media/newsroom/_2023/AdvanceMarketAnalytics.webp';
import ImgYahooFinance from '../../../../img/media/newsroom/_2023/Yahoo_Finance_logo.png';


const template = () => {
  return (
    <>
      <Box id="2023">
        <Box sx={{ mb: 1 }}>
          <Typography variant="caption" color={blueGrey[200]}>
            <strong>2023</strong>
          </Typography>
        </Box>
        <Grid container spacing={4}>
        <NewsArticle
            img={ImgFastComp}
            alt="Article Header Image"
            link="https://www.fastcompany.com/90994736/15-ways-to-keep-your-product-or-service-relevant-after-the-launch"
            title="15 ways to keep your product or service relevant after the launch"
            date="December 11, 2023"
          />
        <NewsArticle
            img={ImgFastComp}
            alt="Article Header Image"
            link="https://www.fastcompany.com/90990503/clinical-trials-with-ai-chasing-efficiency-and-inclusive-participation"
            title="Clinical Trials with AI: Chasing efficiency and inclusive participation"
            date="December 01, 2023"
          />
        <NewsArticle
            img={ImgMDGH}
            alt="Article Header Image"
            link="https://www.atticusmed.com/news/Blog%20Post%20Title%20One-el8ek"
            title="Atticus Medical & Medicines Development for Global Health enroll first patient in Phase 2b study of moxidectin in scabies"
            date="Nov 28, 2023"
          />
        <NewsArticle
            img={ImgWVC}
            alt="Article Header Image"
            link="https://www.terrapinn.com/conference/world-vaccine-immunotherapy-congress-west-coast/agenda.stm"
            title="World Vaccine Congress, West Coast, 2023"
            date="Nov 28, 2023"
          />
        <NewsArticle
            img={ImgForbes}
            alt="Article Header Image"
            link="https://www.forbes.com/sites/forbestechcouncil/2023/11/14/ai-for-efficient-clinical-trials-its-risky-but-optimism-rules"
            title="AI For Efficient Clinical Trials: It's Risky, But Optimism Rules"
            date="Nov 14, 2023"
          />
        <NewsArticle
            img={ImgGartner}
            alt="Article Header Image"
            link="https://www.gartner.com/doc/4558399"
            title="Hype Cycle for Life Science Clinical Development, 2023"
            date="July 21, 2023"
          />
        <NewsArticle
            img={Imgdia}
            alt="Article Header Image"
            link="https://www.diaglobal.org/en/flagship/dia-2023/about/conference"
            title="DIA Conference, 2023"
            date="June 25, 2023"
          />
        <NewsArticle
          img={ImgFeatCust}
          alt="Article Header Image"
          link="https://tech.einnews.com/pr_news/639257661/the-top-clinical-trial-management-software-according-to-the-featuredcustomers-summer-2023-customer-success-report"
          title="The Top Clinical Trial Management Software According to the FeaturedCustomers Summer 2023 Customer Success Report"
          date="June 13, 2023"
        />
        <NewsArticle
            img={ImgAxtria}
            alt="Article Header Image"
            link="https://www.axtria.com/about-us/partnerships-alliances/"
            title="Partnership with Axtria"
            date="June 05, 2023"
          />
        <NewsArticle
          img={ImgPlosNTD}
          alt="Article Header Image"
          link="https://journals.plos.org/plosntds/article?id=10.1371/journal.pntd.0011365&rev=2"
          title="A randomized, open-label study of the tolerability and efficacy for treatment of onchocerciasis"
          date="May 19, 2023"
        />
        <NewsArticle
            img={ImgYahooFinance}
            alt="Article Header Image"
            link="https://finance.yahoo.com/news/cliniops-awarded-patent-pioneering-system-150000086.html"
            title="CliniOps Awarded Patent for Pioneering System to Collect Location Agnostic Clinical and Non-Clinical Data"
            date="May 18, 2023"
        /> 
        <NewsArticle
            img={Imgacrp}
            alt="Article Header Image"
            link="https://2024.acrpnet.org/highlights-2023"
            title="ACRP Conference, 2023"
            date="April 29, 2023"
          /> 
        <NewsArticle
          img={Imgbmc}
          alt="Article Header Image"
          link="https://bmcinfectdis.biomedcentral.com/articles/10.1186/s12879-023-08216-w"
          title="HIV matters when diagnosing TB in young children: an ancillary analysis in children enrolled in the INPUT stepped wedge cluster randomized study"
          date="April 17, 2023"
        />
        <NewsArticle
            img={ImgAMA}
            alt="Article Header Image"
            link="https://www.advancemarketanalytics.com/reports/131471-global-pharma-clinical-trial-digitization-market"
            title="Advance Market Analytics"
            date="April 01, 2023"
          />
        <NewsArticle
            img={Imgfob}
            alt="Article Header Image"
            link="https://www.terrapinn.com/conference/festival-of-biologics-usa/speaker-avik-PAL.stm"
            title="Festival of Biologics USA, 2023"
            date="March 20, 2023"
          /> 
        <NewsArticle
          img={Imguspto}
          alt="Article Header Image"
          link="https://ppubs.uspto.gov/dirsearch-public/print/downloadPdf/11600396"
          title="CliniOps Platform receives Patent approval from USPTO, for collecting location agnostic clinical and non-clinical data"
          date="March 07, 2023"
          />
        <NewsArticle
            img={Imgacrp}
            alt="Article Header Image"
            link="https://acrpnet.org/2023/03/03/using-disruptive-and-convergent-technologies-to-catch-more-waves/"
            title="Using Disruptive and Convergent Technologies to ‘Catch More Waves’"
            date="March 07, 2023"
          /> 
        <NewsArticle
            img={Imgiscr}
            alt="Article Header Image"
            link="https://www.iscr.org/pdf/ISCR%20Conf%20Flyer_23-Sept-22.pdf"
            title="ISCR Annual Conference, 2023"
            date="February 25, 2023"
          /> 
        <NewsArticle
            img={Imgscopes}
            alt="Article Header Image"
            link="https://www.scopesummit.com/23/programs"
            title="Driving Innovation: In Hybrid and Decentralized Clinical Trials (DCT)"
            date="February 06, 2023"
          /> 
          <NewsArticle
            img={Imgnjacts}
            alt="Article Header Image"
            link="https://rutgers.mediaspace.kaltura.com/media/GMT20230125-220305_Recording_1920x1080/1_o7i84u2n"
            title= "Diversity and Inclusion in Site Identification Panel Discussion"
            date="January 26, 2023"
          /> 
          <NewsArticle
            img={Imgnjacts}
            alt="Article Header Image"
            link="https://www.linkedin.com/posts/rutgers-shp-clinical-research-management-network_diversityandinclusion-graduateschool-njacts-activity-7024040046395564032-bO3Q/"
            title="NJ Acts: Diversity and Inclusion in Site Identification"
            date="January 25, 2023"
          /> 
         
        </Grid>                
      </Box>
    </>
  );
}

export default template;